<template>
  <section class="page">
    <div class="page-title-block">
      <h3>Главная</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainPage',
};
</script>
